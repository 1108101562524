import {
  FETCH_LINE_USERS,
  FETCH_LINE_USERS_SUCCESS,
  FETCH_LINE_USERS_FAIL,
  LINK_USER,
  LINK_USER_SUCCESS,
  LINK_USER_FAIL,
  UNLINK_USER,
  UNLINK_USER_SUCCESS,
  UNLINK_USER_FAIL,
  DELETE_LINE_USER,
  DELETE_LINE_USER_SUCCESS,
  DELETE_LINE_USER_FAIL,
} from "./actionTypes"

export const fetchLineUsers = () => ({
  type: FETCH_LINE_USERS,
})

export const fetchLineUsersSuccess = users => ({
  type: FETCH_LINE_USERS_SUCCESS,
  payload: users,
})

export const fetchLineUsersFail = error => ({
  type: FETCH_LINE_USERS_FAIL,
  payload: error,
})

export const linkUser = (lineUserId, appUserId) => ({
  type: LINK_USER,
  payload: { lineUserId, appUserId },
})

export const linkUserSuccess = updatedUser => ({
  type: LINK_USER_SUCCESS,
  payload: updatedUser,
})

export const linkUserFail = error => ({
  type: LINK_USER_FAIL,
  payload: error,
})

export const unlinkUser = lineUserId => ({
  type: UNLINK_USER,
  payload: lineUserId,
})

export const unlinkUserSuccess = updatedUser => ({
  type: UNLINK_USER_SUCCESS,
  payload: updatedUser,
})

export const unlinkUserFail = error => ({
  type: UNLINK_USER_FAIL,
  payload: error,
})

export const deleteLineUser = lineUserId => ({
  type: DELETE_LINE_USER,
  payload: lineUserId,
})

export const deleteLineUserSuccess = deletedUserId => ({
  type: DELETE_LINE_USER_SUCCESS,
  payload: deletedUserId,
})

export const deleteLineUserFail = error => ({
  type: DELETE_LINE_USER_FAIL,
  payload: error,
})
