import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"

// アクションとリデューサーをインポート（後で作成）
import {
  fetchLineUsers,
  linkUser,
  unlinkUser,
  deleteLineUser,
} from "../../store/lineUser/actions"

const LineUsers = () => {
  const dispatch = useDispatch()
  const lineUsers = useSelector(state => state.lineUserReducer.lineUsers)
  const [modal, setModal] = useState(false)
  const [selectedLineUser, setSelectedLineUser] = useState(null)
  const [linkedUserId, setLinkedUserId] = useState("")

  useEffect(() => {
    dispatch(fetchLineUsers())
  }, [dispatch])

  const toggleModal = () => setModal(!modal)

  const handleLinkUser = () => {
    dispatch(linkUser(selectedLineUser.id, linkedUserId))
    toggleModal()
  }

  const handleUnlinkUser = lineUserId => {
    dispatch(unlinkUser(lineUserId))
  }

  const handleDeleteUser = lineUserId => {
    if (window.confirm("本当にこのLineユーザーを削除しますか？")) {
      dispatch(deleteLineUser(lineUserId))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="システム管理" breadcrumbItem="Lineユーザー管理" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Lineユーザー一覧</h4>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th>Line ID</th>
                        <th>表示名</th>
                        <th>紐づけユーザーID</th>
                        <th>アクション</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lineUsers.map(user => (
                        <tr key={user.id}>
                          <td>{user.lineId}</td>
                          <td>{user.displayName}</td>
                          <td>{user.linkedUserId || "未紐づけ"}</td>
                          <td>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setSelectedLineUser(user)
                                toggleModal()
                              }}
                            >
                              紐づけ
                            </Button>{" "}
                            <Button
                              color="warning"
                              size="sm"
                              onClick={() => handleUnlinkUser(user.id)}
                            >
                              紐づけ解除
                            </Button>{" "}
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => handleDeleteUser(user.id)}
                            >
                              削除
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>ユーザー紐づけ</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="linkedUserId">アプリユーザーID</Label>
              <Input
                type="text"
                name="linkedUserId"
                id="linkedUserId"
                value={linkedUserId}
                onChange={e => setLinkedUserId(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleLinkUser}>
            紐づけ
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            キャンセル
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default LineUsers
