import { call, put, takeEvery } from "redux-saga/effects"
import {
  FETCH_LINE_USERS,
  LINK_USER,
  UNLINK_USER,
  DELETE_LINE_USER,
} from "./actionTypes"
import {
  fetchLineUsersSuccess,
  fetchLineUsersFail,
  linkUserSuccess,
  linkUserFail,
  unlinkUserSuccess,
  unlinkUserFail,
  deleteLineUserSuccess,
  deleteLineUserFail,
} from "./actions"
import {
  getLineUsers,
  linkLineUser,
  unlinkLineUser,
  deleteLineUser,
} from "../../helpers/backend_helper"

function* fetchLineUsersSaga() {
  try {
    const response = yield call(getLineUsers)
    yield put(fetchLineUsersSuccess(response))
  } catch (error) {
    yield put(fetchLineUsersFail(error))
  }
}

function* linkUserSaga(action) {
  try {
    const { lineUserId, appUserId } = action.payload
    const response = yield call(linkLineUser, lineUserId, appUserId)
    yield put(linkUserSuccess(response))
  } catch (error) {
    yield put(linkUserFail(error))
  }
}

function* unlinkUserSaga(action) {
  try {
    const response = yield call(unlinkLineUser, action.payload)
    yield put(unlinkUserSuccess(response))
  } catch (error) {
    yield put(unlinkUserFail(error))
  }
}

function* deleteLineUserSaga(action) {
  try {
    yield call(deleteLineUser, action.payload)
    yield put(deleteLineUserSuccess(action.payload))
  } catch (error) {
    yield put(deleteLineUserFail(error))
  }
}

function* lineUserSaga() {
  yield takeEvery(FETCH_LINE_USERS, fetchLineUsersSaga)
  yield takeEvery(LINK_USER, linkUserSaga)
  yield takeEvery(UNLINK_USER, unlinkUserSaga)
  yield takeEvery(DELETE_LINE_USER, deleteLineUserSaga)
}

export default lineUserSaga
