import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

const getLineUsers = async () => {
  const response = await get(url.GET_LINE_USERS)
  return response
}

const linkLineUser = async (lineUserId, appUserId) => {
  const response = await post(url.POST_LINE_USER, {
    lineUserId,
    appUserId,
  })
  return response
}

const unlinkLineUser = async lineUserId => {
  const response = await put(url.PUT_LINE_USER, {
    lineUserId,
    appUserId: null, // アプリユーザーIDをnullに設定してリンク解除
  })
  return response
}

const deleteLineUser = async lineUserId => {
  const response = await del(`${url.DELETE_LINE_USER}/${lineUserId}`)
  return response
}

export { getLineUsers, linkLineUser, unlinkLineUser, deleteLineUser }
