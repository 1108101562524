import React from "react"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

import UserProfile from "pages/Authentication/user-profile"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// 各種ページのインポート
import Flows from "../pages/Flows/index"
import MarketingVolume from "../pages/Marketing/volume/index"
import MarketingCustomer from "../pages/Marketing/customer/index"
import MarketingTime from "../pages/Marketing/time/index"
import Brands from "../pages/Brands/index"
import Users from "../pages/Users/index"
import Cameras from "../pages/cameras/index"
import Stocks from "pages/Stocks"
import Sales_daily from "pages/Sales_Daily"
import Sales from "pages/Sales"
import SalesAll from "pages/Location/saleAll"
import SalesLoc from "pages/Location/saleLoc"
import Customers from "pages/Customers/index"
import Customers2 from "pages/Customers/index2"
import Company from "pages/Company/index"
import Ipcs from "pages/Ipcs/index"
import Shelfs from "pages/Shelfs/index"
import Location from "pages/Location/index"
import SoldOut from "pages/SoldOut/index"
import Warehouse from "pages/Warehouse"
import Goods from "pages/goods/index"
import Vehicle from "pages/Vehicle/index"
import Suprise from "pages/surpriseGoods/index"
import DeliveryPlanCreate from "pages/DeliveryPlan/DeliveryPlanCreate"
import DeliveryPlanMap from "pages/DeliveryPlan/DeliveryPlanMap"
import DeliveryPlanVehicle from "pages/DeliveryPlan/DeliveryPlanVehicle"
import DeliveryPlanConfirm from "pages/DeliveryPlan/DeliveryPlanConfirm"
import DeliveryPlanExecute from "pages/DeliveryPlan/DeliveryPlanExecute"
import DeliveryPlanComplete from "pages/DeliveryPlan/DeliveryPlanComplete"
import DeliveryPlanSelect from "pages/DeliveryPlan/DeliveryPlanSelect"
import DeliveryPlanShow from "pages/DeliveryPlan/DeliveryPlanShow"
import VehicleStock from "pages/VehicleStock"
import WarehouseStock from "pages/WarehouseStock"
import DeliveryPlanLoading from "pages/DeliveryPlan/DeliveryPlanLoading"
import DeliveryPlanDelivery from "pages/DeliveryPlan/DeliveryPlanDelivery"

import LineUsers from "../pages/LineUsers/index"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/flows", component: <Flows /> },
  { path: "/marketing/volume", component: <MarketingVolume /> },
  { path: "/marketing/customer", component: <MarketingCustomer /> },
  { path: "/marketing/time", component: <MarketingTime /> },
  { path: "/brands", component: <Brands /> },
  { path: "/users", component: <Users /> },
  { path: "/cameras", component: <Cameras /> },
  { path: "/stocks", component: <Stocks /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/sales-daily", component: <Sales_daily /> },
  { path: "/sales", component: <Sales /> },
  { path: "/salesAll", component: <SalesAll /> },
  { path: "/salesLoc", component: <SalesLoc /> },
  { path: "/customers", component: <Customers /> },
  { path: "/customers2", component: <Customers2 /> },
  { path: "/companies", component: <Company /> },
  { path: "/ipcs", component: <Ipcs /> },
  { path: "/shelfs", component: <Shelfs /> },
  { path: "/location", component: <Location /> },
  { path: "/sold-out", component: <SoldOut /> },
  { path: "/warehouse", component: <Warehouse /> },
  { path: "/Goods", component: <Goods /> },
  { path: "/vehicle", component: <Vehicle /> },
  { path: "/suprise", component: <Suprise /> },
  { path: "/delivery-plan/create", component: <DeliveryPlanCreate /> },
  { path: "/delivery-plan/map", component: <DeliveryPlanMap /> },
  { path: "/delivery-plan/vehicle-select", component: <DeliveryPlanVehicle /> },
  { path: "/delivery-plan/confirm", component: <DeliveryPlanConfirm /> },
  { path: "/delivery-plan/execute", component: <DeliveryPlanExecute /> },
  { path: "/delivery-plan/complete", component: <DeliveryPlanComplete /> },
  { path: "/delivery-plan/select", component: <DeliveryPlanSelect /> },
  { path: "/delivery-plan/show", component: <DeliveryPlanShow /> },
  { path: "/vehicle-stock", component: <VehicleStock /> },
  { path: "/warehouse-stock", component: <WarehouseStock /> },
  { path: "/delivery-plan/loading", component: <DeliveryPlanLoading /> },
  { path: "/delivery-plan/delivery", component: <DeliveryPlanDelivery /> },
  { path: "/line-users", component: <LineUsers /> },
  { path: "/", component: <Dashboard /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  { path: "/pages-login", component: <Login1 /> },
]

export { userRoutes, authRoutes }
