export const FETCH_LINE_USERS = "FETCH_LINE_USERS"
export const FETCH_LINE_USERS_SUCCESS = "FETCH_LINE_USERS_SUCCESS"
export const FETCH_LINE_USERS_FAIL = "FETCH_LINE_USERS_FAIL"

export const LINK_USER = "LINK_USER"
export const LINK_USER_SUCCESS = "LINK_USER_SUCCESS"
export const LINK_USER_FAIL = "LINK_USER_FAIL"

export const UNLINK_USER = "UNLINK_USER"
export const UNLINK_USER_SUCCESS = "UNLINK_USER_SUCCESS"
export const UNLINK_USER_FAIL = "UNLINK_USER_FAIL"

export const DELETE_LINE_USER = "DELETE_LINE_USER"
export const DELETE_LINE_USER_SUCCESS = "DELETE_LINE_USER_SUCCESS"
export const DELETE_LINE_USER_FAIL = "DELETE_LINE_USER_FAIL"
