import {
  FETCH_LINE_USERS,
  FETCH_LINE_USERS_SUCCESS,
  FETCH_LINE_USERS_FAIL,
  LINK_USER_SUCCESS,
  LINK_USER_FAIL,
  UNLINK_USER_SUCCESS,
  UNLINK_USER_FAIL,
  DELETE_LINE_USER_SUCCESS,
  DELETE_LINE_USER_FAIL,
} from "./actionTypes"

const initialState = {
  lineUsers: [],
  loading: false,
  error: null,
}

const lineUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LINE_USERS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_LINE_USERS_SUCCESS:
      return {
        ...state,
        lineUsers: action.payload,
        loading: false,
        error: null,
      }
    case FETCH_LINE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case LINK_USER_SUCCESS:
    case UNLINK_USER_SUCCESS:
      return {
        ...state,
        lineUsers: state.lineUsers.map(user =>
          user.id === action.payload.id ? action.payload : user
        ),
        error: null,
      }
    case DELETE_LINE_USER_SUCCESS:
      return {
        ...state,
        lineUsers: state.lineUsers.filter(user => user.id !== action.payload),
        error: null,
      }
    case LINK_USER_FAIL:
    case UNLINK_USER_FAIL:
    case DELETE_LINE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default lineUserReducer
